export const CUSTOM_CHART_OPTS = {
  watermark: true
}

// *** Note: we define ***
//   device.con = energy flowing into the device
//   device.gen = energy flowing out of the device
//
// In case a list of colors is defined, increasing device-counts will iterate through the list.
// In case a single color is defined, increasing device-counts will lead to a brighten of the color.
//
export const CUSTOM_CHART_COLORS = {
  primary: '#87BE22',
  fallback: '#4c4f54',
  gaugeBg: '#d6d6d6',
  colormaps: {
    fromGridToPrimary: [
      '#6B7D8B',
      '#6E847F',
      '#6E847F',
      '#718B73',
      '#749268',
      '#77995C',
      '#7AA150',
      '#7DA845',
      '#80AF39',
      '#83B62D',
      '#87BE22'
    ]
  },
  compRuleConfig: {
    backgroundColor: '#ebedef',
    backgroundColorDt: '#31323a',
    highlightedBackgroundColor: '#87be22',
    highlightedBackgroundColorDt: '#87be22',
    lineColor: '#b1b7c1',
    lineColorDt: '#222',
    textColor: '#2c384af2',
    textColorDt: '#fff'
  },
  dashboard: {
    backgroundColorShowNodeFalse: '#666666',
    backgroundColorShowNodeTrue: '#87BE2277',
    backgroundColorShowNodeFalseDt: '#666666',
    backgroundColorShowNodeTrueDt: '#87BE2299',
    labelTextColor: '#96989a',
    labelTextColorDt: '#96989a',
    fromGrid: '#6b7d8b',
    lineColors: {
      default: '#bbbbbb',
      defaultDt: '#666666'
    },
    nodeColor: '#666666',
    nodeColorDt: '#bbbbbb',
    noFlow: '#aaaaaa',
    toGrid: '#87BE22',
    valueTextColor: '#000000',
    valueTextColorDt: '#FFFFFF'
  },
  forecast: ['#7cb5ec', '#436f99'],
  abstract: {
    con: '#f6917b',
    gen: '#e67ef2'
  },
  battery: {
    // green
    con: ['#33691e', '#709662', '#aec3a6'],
    conservationCharge: '#a9c8a3',
    gen: ['#84be40', '#aed580', '#dcedc9'],
    feedinCharge: '#a9c8a3',
    soc: ['#84be40', '#aed580', '#dcedc9']
  },
  chp: {
    con: ['#412e22', '#81726f'],
    gen: ['#9d4c15', '#c69779']
  },
  container: {
    con: ['#d96d02', '#cc6602'],
    gen: []
  },
  wallbox: {
    // purple
    con: ['#4a148c', '#805baf', '#af9acc'],
    gen: []
  },
  fuelcell: {
    con: ['#920055', '#b64d86'],
    gen: ['#db0078', '#e64da1']
  },
  generic: {
    // user colors
    con: ['#008788', '#4db6ac', '#80cbcb'],
    // pv colors
    gen: ['#ffa600', '#ffc361', '#f8e0aa']
  },
  grid: {
    // gray
    // feed-in
    con: ['#29536b', '#7993a1', '#b5cbd3'],
    // delivery
    gen: ['#6b7d8b', '#9da9b2', '#e1e5e8'],
    peak: '#FF0000',
    processedPriceEurocent: '#3338ff'
  },
  heatingelement: {
    // brown
    con: ['#89721e', '#ac9c62', '#d6ceb0'],
    gen: []
  },
  heatsource: {
    // light-red
    con: ['#f3382c', '#f77b72', '#fcc7c3'],
    gen: []
  },
  heatpump: {
    // red
    con: ['#b71c1c', '#cd6060', '#de9c9c'],
    gen: []
  },
  heatstorage: {
    con: '#f7a35c',
    gen: '#f45050',
    loss: '#008080',
    gain: '#c3e88d'
  },
  cons: {
    con: ['#87BE22']
  },
  consAndGrid: {
    con: ['#87BE22']
  },
  prod: {
    gen: ['#FFDC00']
  },
  prodAndGrid: {
    gen: ['#FFF176']
  },
  pv: {
    // yellow
    con: [],
    gen: ['#ffa600', '#ffc361', '#f8e0aa', '#ffdc00', '#fff176', '#fff9c4', '#ebff00']
  },
  user: {
    // cyan
    con: ['#008788', '#4db6ac', '#80cbcb'],
    gen: ['#1de9b6', '#77f2d3', '#c7faed']
  },
  wind: {
    // blue
    con: ['#0d47a1', '#567ebd', '#97aed6'],
    gen: ['#188ff2', '#64b6f7', '#bce0fb']
  },
  measurand: {
    airConditionerState: '#3091c9',
    avgDiskUsed: '#89721e',
    avgLoad: '#bf7740',
    avgMemoryCommitted: '#0d47a1',
    avgMemoryFree: '#567ebd',
    avgPowerfactor: '#29536b',
    avgTemperature: ['#ff0000', '#ff5040', '#ff957f'],
    batterySoc: ['#84be40', '#aed580', '#dcedc9'], // e.g. of E-Car
    butaneCounter: '#82370c',
    butaneCounterPerTime: '#82370c',
    butaneFlowrate: '#b35620',
    coldwaterCounter: '#050f83',
    coldwaterCounterPerTime: '#050f83',
    coldwaterFlowrate: '#050f83',
    containerDoorState: '#cc7621',
    containerStorageBoxState: '#665d5d',
    containerSyncBoxState: '#c7b854',
    current: ['#a57d00', '#cc9f13', '#f0ca56'], // AC current
    dcCurrent: ['#ffa600', '#ffc361', '#f8e0aa'],
    dcPower: ['#1de9b6', '#77f2d3', '#c7faed'],
    dcVoltage: ['#ffdc00', '#fff176', '#fff9c4'],
    enclosureTemperature: '#ff957f',
    fireAlarmState: '#f22229',
    flowrate: ['#4a148c', '#805baf', '#af9acc'],
    flowrateDemand: ['#4a148c', '#805baf', '#af9acc'],
    flowrateInDemand: '#9900cc',
    flowrateInSupply: ['#4a148c', '#805baf', '#af9acc'],
    flowrateOut: ['#9c27b0', '#ba68c8', '#805baf'],
    flowrateOutDemand: '#9933ff',
    flowrateOutSupply: ['#9c27b0', '#ba68c8', '#805baf'],
    frequency: '#cc00ff',
    harmonicDistortionPnCurrent: '#a57d00',
    harmonicDistortionPnCurrentPhasea: '#a57d00',
    harmonicDistortionPnCurrentPhaseb: '#cc9f13',
    harmonicDistortionPnCurrentPhasec: '#f0ca56',
    harmonicDistortionPnVoltage: '#8c9710',
    harmonicDistortionPnVoltagePhasea: '#8c9710',
    harmonicDistortionPnVoltagePhaseb: '#b6c509',
    harmonicDistortionPnVoltagePhasec: '#ebff00',
    heatsinkTemperature: '#ffaf87',
    humidity: '#30c2c9',
    humidityEnv: '#106269',
    hydrogenCounter: '#16b5a5',
    hydrogenCounterPerTime: '#16b5a5',
    hydrogenFlowrate: '#32e3d1',
    inverterCurrent: ['#ffa600', '#ffc361', '#f8e0aa'],
    inverterPower: '#7d04ff',
    inverterVoltage: ['#8c9710', '#b6c509', '#ebff00'], // AC Voltage
    maxDiskUsed: '#ac9c62',
    maxLoad: '#bfb240',
    maxMemoryCommitted: '#0a3292',
    maxMemoryFree: '#97aed6',
    maxTemperature: ['#b71c1c', '#cd6060', '#de9c9c'],
    minTemperature: ['#ff5500', '#ff8040', '#ffaf87'],
    naturalgasCounter: '#0a784a',
    naturalgasCounterPerTime: '#0a784a',
    naturalgasFlowrate: '#25c482',
    oilCounter: '#877070',
    oilCounterPerTime: '#877070',
    oilFlowrate: '#ad9a9a',
    pcsCcuState: '#0409ff',
    pcsIpuState: '#147287',
    pcsMcuState: '#7d04ff',
    power: ['#008788', '#4db6ac', '#80cbcb'], // AC
    powerDemand: ['#2196f3', '#64b6f7', '#bce0fb'], // AC
    powerfactor: '#29536b',
    powerfactorPhasea: '#29536b',
    powerfactorPhaseb: '#7993a1',
    powerfactorPhasec: '#b5cbd3',
    powerSupply: ['#0d47a1', '#567ebd', '#97aed6'], // AC
    processedPriceDollarcent: '#3338ff',
    processedPriceEurocent: '#3338ff',
    processedPriceUnknowncurrency: '#3338ff',
    propaneCounter: '#e09a3f',
    propaneCounterPerTime: '#e09a3f',
    propaneFlowrate: '#e8a956',
    reactivePower: '#7d04ff',
    setpointPower: '#70808f',
    soc: ['#84be40', '#aed580', '#dcedc9'],
    soh: ['#29536b', '#7993a1', '#b5cbd3'],
    temperature: ['#ff0000', '#ff5040', '#ff957f'],
    temperatureBackupheating: '#cc0000',
    temperatureExternal: '#ff704d',
    temperatureIn: ['#db0078', '#e64da1', '#f4b2d6'], // In < Out (of heatsource)
    temperatureInDemand: '#993333', // InDemand > OutDemand (of heatstorage)
    temperatureInSupply: ['#db0078', '#e64da1', '#f4b2d6'], // InSupply < OutSupply (of heatstorage)
    temperatureOut: ['#920055', '#b64d86', '#d399bb'],
    temperatureOutDemand: '#cc6600',
    temperatureOutSupply: ['#920055', '#b64d86', '#d399bb'],
    thermalpower: '#3366ff',
    thermalpowerInDemand: '#000066',
    thermalpowerInSupply: '#3366ff',
    thermalpowerOutDemand: '#0099cc',
    thermalpowerOutSupply: '#666699',
    thermalpowerSupply: '#666699',
    voltage: ['#8c9710', '#b6c509', '#ebff00'], // AC Voltage
    wallboxState: '#9e6e06',
    warmwaterCounter: '#83050f',
    warmwaterCounterPerTime: '#83050f',
    warmwaterFlowrate: '#83050f',
    waterCounter: '#4747b3',
    waterCounterPerTime: '#4747b3',
    waterFlowrate: '#6363ba'
  },
  yAxis: {
    charge: '#84be40',
    counterPerTime: '#23a1db',
    current: '#ffa600',
    disk: '#89721e',
    energy: '#2196f3',
    factor: '#84be40',
    flux: '#0000ff',
    frequency: '#cc00ff',
    load: '#bf7740',
    memory: '#567ebd',
    percent: '#84be40',
    power: '#2196f3',
    price: '#3338ff',
    priceDoller: '#3338ff',
    priceEuro: '#3338ff',
    status: '#0409ff',
    temperature: '#ff0000',
    voltage: '#ffdc00',
    volume: '#23a1db'
  },
  weather: {
    clearSky: '#97aed6',
    globalRadiation: '#7cb5ec',
    precipitation: '#7cb5ec',
    temperature: '#ff0000'
  }
}
